import { zodResolver } from "@hookform/resolvers/zod"
import { useForm } from "react-hook-form"
import { z } from "zod"
import { Button } from "~/ui/button"
import { DialogFooter, DialogHeader, DialogTitle } from "~/ui/dialog"
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/ui/form"
import { RadioGroup, RadioGroupItem } from "~/ui/radio-group"
import { Separator } from "~/ui/separator"
import { useSafeMutation } from "~/common/useSafeMutation"
import toast from "react-hot-toast"
import { getMetaVar } from "~/common/getMetaVar"
import { USER_UPDATE_MUTATION } from "~/common/userUpdateMutation"
import { cn } from "~/lib/utils"
import { Translation } from "~/common/Translation"
import { useWizard } from "~/ui/Wizard"
import { AhoyEventTypeEnum, CommunitySlug } from "~/__generated__/graphql"
import { useLogEvent } from "~/analytics/EventsContext"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { useEffect, useRef } from "react"
import { LoadingStep } from "./LoadingStep"
import { useCommunityClassname } from "~/community/useCommunity"

const intakeFormSchema = z.object({
  mostExcitedAbout: z.string(),
})
type IntakeFormValues = z.infer<typeof intakeFormSchema>

export const IntentStep = () => {
  const { currentUser } = useCurrentUser()
  const [runUserUpdate, { loading }] = useSafeMutation(USER_UPDATE_MUTATION)
  const { logEvent } = useLogEvent()
  const { goToStep } = useWizard()
  const { handleSubmit, ...form } = useForm<IntakeFormValues>({
    resolver: zodResolver(intakeFormSchema),
  })

  const onSubmit = async (values: IntakeFormValues) => {
    const { errors } = await runUserUpdate({
      variables: {
        input: {
          mostExcitedAbout: values.mostExcitedAbout,
        },
      },
    })
    if (errors) {
      console.log(errors)
      toast.error("An unexpected error has occurred. Please try again later.")
      return
    }
    logEvent(AhoyEventTypeEnum.IntentQuestionAnswered, {
      selection: values.mostExcitedAbout,
    })

    if (currentUser.activeStripeSubscription || currentUser.admin) {
      goToStep("SocialContractStep", "forward")
    } else {
      goToStep("PricingTableStep", "forward")
    }
  }

  const csrfToken = getMetaVar("csrf-token")

  const hasPerformedNavigationCheckRef = useRef(false)

  useEffect(() => {
    if (hasPerformedNavigationCheckRef.current) return
    hasPerformedNavigationCheckRef.current = true
    if (currentUser.mostExcitedAbout) {
      if (currentUser.activeStripeSubscription || currentUser.admin) {
        goToStep("SocialContractStep", "forward", false)
      } else {
        goToStep("PricingTableStep", "forward", false)
      }
    }
  }, [
    currentUser.admin,
    currentUser.mostExcitedAbout,
    currentUser.activeStripeSubscription,
    goToStep,
  ])

  if (!hasPerformedNavigationCheckRef.current) return <LoadingStep />

  return (
    <div className="">
      <DialogHeader>
        <DialogTitle>
          <Translation ns="intentStep" i18nKey={"title"} />
        </DialogTitle>
      </DialogHeader>

      <Form {...form} handleSubmit={handleSubmit}>
        <form className="flex flex-col gap-8" onSubmit={handleSubmit(onSubmit)}>
          <input type="hidden" name="authenticity_token" value={csrfToken} />
          <input type="hidden" name="return_url" value={window.location.href} />
          <FormField
            control={form.control}
            name="mostExcitedAbout"
            render={({ field }) => (
              <FormItem className="space-y-4">
                <FormControl>
                  <RadioGroup
                    onValueChange={field.onChange}
                    defaultValue={field.value}
                  >
                    <Translation
                      ns="intentStep"
                      i18nKey={"questions"}
                      components={{
                        Question: <Question />,
                      }}
                    />
                  </RadioGroup>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />

          <Separator />

          <DialogFooter>
            <Button
              type="submit"
              disabled={!form.formState.isValid || loading}
              className="bg-highlight"
            >
              Next
            </Button>
            <StepProgress step={1} />
          </DialogFooter>
        </form>
      </Form>
    </div>
  )
}

IntentStep.displayName = "IntentStep"

const Question = ({ question }: { question?: string }) => {
  if (!question) return "Missing question"
  return (
    <FormItem variant="radio">
      <FormControl>
        <RadioGroupItem value={question} />
      </FormControl>
      <FormLabel>{question}</FormLabel>
    </FormItem>
  )
}

const StepProgress = ({ step }: { step: number }) => {
  const ccls = useCommunityClassname()
  return (
    <ul className="flex gap-2">
      {Array.from({ length: 4 }).map((_, index) => (
        <li
          key={index}
          className={cn(
            "h-2 w-2 rounded-full bg-muted",
            step === index + 1 &&
              ccls({
                [CommunitySlug.Gotomillions]: "bg-primary",
                default: "bg-highlight",
              })
          )}
        />
      ))}
    </ul>
  )
}
