import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "~/ui/form"
import {
  Control,
  FieldPath,
  FieldValues,
  useFormContext,
} from "react-hook-form"
import { Input, InputProps } from "~/ui/input"
import { cn } from "~/lib/utils"

export const TextField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  control,
  name,
  description,
  required,
  label,
  labelClassName,
  containerClassName,
  hideErrorMessage = false,
  ...inputProps
}: {
  label: string
  labelClassName?: string
  containerClassName?: string
  description?: string
  required?: boolean
  hideErrorMessage?: boolean
  control: Control<TFieldValues> | undefined
  name: TName
} & InputProps) => {
  const { formState } = useFormContext()
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className={containerClassName}>
          <FormLabel className={labelClassName} required={required}>
            {label}
          </FormLabel>
          <FormControl>
            <Input
              {...field}
              {...inputProps}
              className={cn(!!formState.errors[name] && "border-red-500")}
            />
          </FormControl>
          {description && <FormDescription>{description}</FormDescription>}
          <FormMessage className={cn(hideErrorMessage && "sr-only")} />
        </FormItem>
      )}
    />
  )
}
