import { useEffect, useMemo, useRef, useState } from "react"
import { gql } from "~/__generated__"
import {
  NotificationsResultFragment,
  NotificationActionEnum,
  MarkReadEnum,
  ArticleRevision,
  ArticleContentTypeEnum,
  AhoyEventTypeEnum,
  CommunitySlug,
  ArticleStateEnum,
} from "~/__generated__/graphql"
import { useLazyQuery } from "@apollo/client"
import { useSafeMutation } from "~/common/useSafeMutation"
import { MARK_READ_MUTATION } from "~/common/userUpdateMutation"
import { Link } from "react-router-dom"
import {
  articlePath,
  benchmarkPath,
  editArticlePath,
  eventsPath,
  myContentPath,
  postPath,
  dmPath,
} from "~/common/paths"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "~/ui/dropdown-menu"
import { cn } from "~/lib/utils"
import { LoadingIndicator } from "~/ui/LoadingIndicator"
import { AvatarWithFallback } from "~/ui/AvatarWithFallback"
import { InfiniteLoadMore } from "~/ui/InfiniteLoadMore"
import { humanizedTimeSpan } from "~/common/humanizedTimeSpan"
import { parseISO } from "date-fns"
import pluralize from "pluralize"
import Bell from "../images/icons/bell.svg?react"
import DropdownArrow from "~/images/dropdown-arrow.svg?react"
import Cup from "../images/icons/cup.svg?react"
import Driver from "../images/icons/driver.svg?react"
import VideoAdd from "../images/icons/video-add.svg?react"
import { formatDate as formatCalendarDate } from "~/common/formatDate"
import { IconButton } from "~/ui/IconButton"
import { FormattedContent } from "~/components/FormattedContent"
import { useLogEvent } from "~/analytics/EventsContext"
import { useCommunityClassname } from "~/community/useCommunity"
import { UserName, UserNameProps } from "~/directory/UserName"

const truncatedDateFormats = {
  past: [
    { ceiling: 60, text: "$seconds s" },
    { ceiling: 3600, text: "$minutes m" },
    { ceiling: 86400, text: "$hours h" },
    { ceiling: 2629744, text: "$days d" },
    { ceiling: 31556926, text: "$months mo" },
    { ceiling: null, text: "$years y" },
  ],
  future: [
    { ceiling: 60, text: "" },
    { ceiling: 3600, text: "" },
    { ceiling: 86400, text: "" },
    { ceiling: 2629744, text: "" },
    { ceiling: 31556926, text: "" },
    { ceiling: null, text: "" },
  ],
}

const NotificationTitle = ({
  children,
}: {
  children: React.ReactNode | string
}) => {
  return (
    <span
      className={cn(
        "font-medium text-foreground overflow-hidden text-ellipsis align-text-bottom",
        typeof children === "string" && children.length > 30 && "line-clamp-3",
        typeof children !== "string" && "line-clamp-3"
      )}
    >
      {children}
    </span>
  )
}

const NotificationDetails = ({
  notification,
}: {
  notification: NotificationsResultFragment
}) => (
  <div className="flex flex-col gap-2 items-end text-textTimestamp">
    <div>
      {humanizedTimeSpan(
        parseISO(notification.postedAt),
        truncatedDateFormats
      ).replace(/\s/g, "")}
    </div>
    <div
      className={cn(
        "w-[8px] h-[8px] rounded-full bg-transparent mr-1",
        notification.unread && "bg-highlight"
      )}
    ></div>
  </div>
)

const NotificationElement = ({
  notification,
  children,
  icon,
}: {
  notification: NotificationsResultFragment
  children: React.ReactNode
  icon?: React.ReactNode
}) => {
  if (notification.actor && !icon) {
    return (
      <div className="w-[calc(100%+4px)] ml-[-4px] flex items-center">
        <AvatarWithFallback user={notification.actor} className="mr-2" />
        <div className="flex-1 pr-3">
          <div className="font-semibold mb-1">
            <UserName user={notification.actor} />
          </div>
          <div className="text-textMedium">{children}</div>
        </div>
        <NotificationDetails notification={notification} />
      </div>
    )
  } else {
    return (
      <div className="w-full flex items-center">
        {icon && (
          <div className="border rounded-full h-[32px] w-[32px] border-borderColor flex items-center justify-center text-primary bg-white mr-3">
            {icon}
          </div>
        )}
        <div className="flex-1 pr-3">
          <div className="text-textMedium">{children}</div>
        </div>
        <NotificationDetails notification={notification} />
      </div>
    )
  }
}

export const NotificationDropdown = ({
  unreadCount,
}: {
  unreadCount?: number
}) => {
  const [open, setOpen] = useState<boolean>(false)

  const [runMarkRead] = useSafeMutation(MARK_READ_MUTATION)
  const ccls = useCommunityClassname()

  const [
    fetchNotifications,
    { data: currentData, previousData, loading, error, fetchMore },
  ] = useLazyQuery(NOTIFICATIONS_QUERY_DOCUMENT, {
    notifyOnNetworkStatusChange: true,
  })

  const data = currentData || previousData

  const notifications = useMemo(() => {
    return data?.notifications.edges.map((e) => e.node) || []
  }, [data])

  const markNotificationRead = (notificationId: string) => {
    runMarkRead({
      variables: {
        input: {
          target: MarkReadEnum.Notifications,
          notificationId,
        },
      },
    })
  }

  useEffect(() => {
    if (!open) return

    console.log("marking all read")

    runMarkRead({
      variables: {
        input: {
          target: MarkReadEnum.Notifications,
        },
      },
    })
  }, [notifications, runMarkRead, open])

  const onOpenChange = (open: boolean) => {
    if (open && !loading) {
      fetchNotifications()
    }
    setOpen(open)
  }

  const pathForNotification = (notification: NotificationsResultFragment) => {
    if (notification.source?.__typename === "CalendarEvent") {
      return eventsPath.pattern
    } else if (notification.source?.__typename === "Post") {
      return postPath({ postId: notification.source.id })
    } else if (
      notification.source?.__typename === "Celebration" &&
      notification.action === NotificationActionEnum.Birthday
    ) {
      return dmPath({ otherUserId: notification.source.user.id })
    } else if (notification.source?.__typename === "Article") {
      if (
        notification.action === NotificationActionEnum.Submitted ||
        notification.action === NotificationActionEnum.Approved
      ) {
        return (
          editArticlePath({ articleId: notification.source.id }) + "?preview"
        )
      }
      if (
        notification.action === NotificationActionEnum.Collaborator ||
        notification.action === NotificationActionEnum.CollaboratorAccepted ||
        notification.action === NotificationActionEnum.CollaboratorRejected
      ) {
        return myContentPath.pattern
      }
      if (notification.action === NotificationActionEnum.EditsRequested) {
        return (
          editArticlePath({ articleId: notification.source.id }) + "?revisions"
        )
      }
      return articlePath({ articleId: notification.source.id })
    } else if (
      notification.action === NotificationActionEnum.Connected ||
      notification.action === NotificationActionEnum.Reprompt
    ) {
      return benchmarkPath.pattern
    } else if (
      notification.action === NotificationActionEnum.Connect3 ||
      notification.action === NotificationActionEnum.Connect30
    ) {
      return benchmarkPath.pattern + "?connect"
    }
    return "/"
  }

  const renderNotification = (notification: NotificationsResultFragment) => {
    if (notification.action === NotificationActionEnum.Tagged) {
      return (
        <NotificationElement notification={notification}>
          Tagged you in a{" "}
          <span className="text-picton-blue">
            {taggedOn(notification.source)}
          </span>
          .
        </NotificationElement>
      )
    } else if (notification.source?.__typename === "CalendarEvent") {
      if (notification.action === NotificationActionEnum.Event) {
        return (
          <NotificationElement
            notification={notification}
            icon={<VideoAdd className="w-[16px] h-auto" />}
          >
            New event alert!{" "}
            <NotificationTitle>{notification.source.name}</NotificationTitle> is
            live. RSVP to hold your cal.
          </NotificationElement>
        )
      }
    } else if (notification.source?.__typename === "Post") {
      if (notification.action === NotificationActionEnum.Retweet) {
        return (
          <NotificationElement notification={notification}>
            Retweeted{" "}
            {notification.source.retweetPost?.content?.length ? (
              <NotificationTitle>
                <FormattedContent
                  content={notification.source.retweetPost.content}
                  withLinks={false}
                />
              </NotificationTitle>
            ) : (
              <span>
                your <span className="text-picton-blue">post</span>
              </span>
            )}
            .
          </NotificationElement>
        )
      } else if (notification.action === NotificationActionEnum.Commented) {
        const peerCount =
          notification.source.commentersCount -
          1 -
          (notification.source.currentUserHasReplied ? 1 : 0)
        const titleElement = notification.source.content?.length ? (
          <NotificationTitle>
            <FormattedContent
              content={notification.source.content}
              withLinks={false}
            />
          </NotificationTitle>
        ) : (
          <>
            a <span className="text-picton-blue">post</span>
          </>
        )
        return (
          <NotificationElement notification={notification}>
            {peerCount > 0 ? (
              <>
                And{" "}
                <span className="font-medium">
                  {pluralize("peer", peerCount, true)}
                </span>{" "}
                are replying to {titleElement}
              </>
            ) : (
              <>Replied to {titleElement}.</>
            )}
          </NotificationElement>
        )
      } else if (notification.action === NotificationActionEnum.Reacted) {
        const post = notification.source
        return (
          <NotificationElement notification={notification}>
            {post.reactorsCount > 1 ? (
              <span>
                And{" "}
                <span className="font-medium">
                  {pluralize("peer", post.reactorsCount - 1, true)}
                </span>{" "}
                reacted to
              </span>
            ) : (
              <span>Reacted to</span>
            )}{" "}
            {notification.source.content?.length ? (
              <NotificationTitle>
                <FormattedContent
                  content={notification.source.content}
                  withLinks={false}
                />
              </NotificationTitle>
            ) : (
              <span>
                your <span className="text-picton-blue">post</span>
              </span>
            )}
            .
          </NotificationElement>
        )
      }
    } else if (
      notification.source?.__typename === "Celebration" &&
      notification.action === NotificationActionEnum.Birthday
    ) {
      const date = formatCalendarDate(notification.source.date, "MMM do")

      return (
        <NotificationElement notification={notification}>
          🥳 It's {notification.source.user.firstName}'s Birthday! ({date}).
          Wish them a Happy Birthday?
        </NotificationElement>
      )
    } else if (
      notification.source?.__typename === "Article" &&
      notification.source.state !== ArticleStateEnum.Archived
    ) {
      if (notification.action === NotificationActionEnum.Published) {
        if (notification.actor) {
          return (
            <NotificationElement notification={notification}>
              Published{" "}
              {ArticlePublishText({
                title: notification.source.approvedRevision?.title,
                contentType: notification.source.approvedRevision?.contentType,
              })}
            </NotificationElement>
          )
        } else {
          return (
            <NotificationElement
              notification={notification}
              icon={<Driver className="w-[16px] h-auto" />}
            >
              <span className="font-medium">
                {pluralize(
                  "peer",
                  notification.source.collaborators.length,
                  true
                )}
              </span>{" "}
              published{" "}
              {ArticlePublishText({
                title: notification.source.approvedRevision?.title,
                contentType: notification.source.approvedRevision?.contentType,
              })}
            </NotificationElement>
          )
        }
      } else if (notification.action === NotificationActionEnum.Collaborator) {
        return (
          <NotificationElement notification={notification}>
            Added you as a collaborator on{" "}
            <NotificationTitle>
              {notification.source.draftRevision.title}
            </NotificationTitle>
            !
          </NotificationElement>
        )
      } else if (
        notification.action === NotificationActionEnum.CollaboratorAccepted
      ) {
        return (
          <NotificationElement notification={notification}>
            Has accepted your invitation to collaborate on{" "}
            <NotificationTitle>
              {notification.source.draftRevision.title}
            </NotificationTitle>
            .
          </NotificationElement>
        )
      } else if (
        notification.action === NotificationActionEnum.CollaboratorRejected
      ) {
        return (
          <NotificationElement notification={notification}>
            Has declined your invitation to collaborate on{" "}
            <NotificationTitle>
              {notification.source.draftRevision.title}
            </NotificationTitle>
            .
          </NotificationElement>
        )
      } else if (
        notification.action === NotificationActionEnum.CourseReminder
      ) {
        return (
          <NotificationElement notification={notification}>
            {courseTakers(notification.source.firstNBookmarkedUsers)} taking{" "}
            <NotificationTitle>
              {notification.source.approvedRevision?.title || ""}
            </NotificationTitle>
            . Check it out for yourself.
          </NotificationElement>
        )
      } else if (notification.action === NotificationActionEnum.Submitted) {
        return (
          <NotificationElement notification={notification}>
            Submitted{" "}
            <NotificationTitle>
              {notification.source.draftRevision.title}
            </NotificationTitle>{" "}
            for review.
          </NotificationElement>
        )
      } else if (notification.action === NotificationActionEnum.Approved) {
        return (
          <NotificationElement
            notification={notification}
            icon={<Driver className="w-[16px] h-auto" />}
          >
            {notification.actor ? (
              <>
                <span className="font-medium">
                  {notification.actor.firstName}
                </span>{" "}
                has approved{" "}
                <span className="font-medium text-foreground">
                  {
                    (
                      notification.source.approvedRevision ||
                      notification.source.draftRevision
                    ).title
                  }
                </span>
                .
              </>
            ) : (
              <>
                <span className="font-medium text-foreground">
                  {
                    (
                      notification.source.approvedRevision ||
                      notification.source.draftRevision
                    ).title
                  }
                </span>{" "}
                has been approved.
              </>
            )}{" "}
            It will go live in the Library soon!
          </NotificationElement>
        )
      } else if (
        notification.action === NotificationActionEnum.EditsRequested
      ) {
        return (
          <NotificationElement
            notification={notification}
            icon={<Driver className="w-[16px] h-auto" />}
          >
            {notification.actor ? (
              <>
                <span className="font-medium">
                  {notification.actor.firstName}
                </span>{" "}
                requested edits to{" "}
                <span className="font-medium text-foreground">
                  {notification.source.draftRevision.title}
                </span>
              </>
            ) : (
              <>
                Edits have been requested to{" "}
                <span className="font-medium text-foreground">
                  {notification.source.draftRevision.title}
                </span>
              </>
            )}
            .
          </NotificationElement>
        )
      } else if (
        notification.action === NotificationActionEnum.ArticleComment
      ) {
        const peerCount =
          notification.source.commentersCount -
          1 -
          (notification.source.currentUserHasCommented ? 1 : 0)
        const titleElement = (
          <span className="font-medium text-foreground">
            {
              (
                notification.source.approvedRevision ||
                notification.source.draftRevision
              ).title
            }
          </span>
        )
        return (
          <NotificationElement notification={notification}>
            {peerCount > 0 ? (
              <>
                And{" "}
                <span className="font-medium">
                  {pluralize("peer", peerCount, true)}
                </span>{" "}
                commented on {titleElement}.
              </>
            ) : (
              <>Commented on {titleElement}.</>
            )}
          </NotificationElement>
        )
      }
    } else {
      if (notification.action === NotificationActionEnum.Connected) {
        return (
          <NotificationElement
            notification={notification}
            icon={<Cup className="w-[16px] h-auto" />}
          >
            Your HRIS is successfully connected!
          </NotificationElement>
        )
      } else if (notification.action === NotificationActionEnum.Connect3) {
        return (
          <NotificationElement
            notification={notification}
            icon={<Cup className="w-[16px] h-auto" />}
          >
            Unlock your Dashboards for data insights! Connect your HRIS or
            manually add in metrics to view Dashboards.
          </NotificationElement>
        )
      } else if (notification.action === NotificationActionEnum.Connect30) {
        return (
          <NotificationElement
            notification={notification}
            icon={<Cup className="w-[16px] h-auto" />}
          >
            Your peers are loving the Benchmarking tool. Connect Your HRIS or
            manually add in metrics to unlock your Dashboard.
          </NotificationElement>
        )
      } else if (notification.action === NotificationActionEnum.Reprompt) {
        return (
          <NotificationElement
            notification={notification}
            icon={<Cup className="w-[16px] h-auto" />}
          >
            It's been 6 months since you first added your company's metrics.
            Update them for accurate Dashboard results!
          </NotificationElement>
        )
      }
    }
  }

  const bellRef = useRef<HTMLButtonElement>(null)
  const dropdownContentRef = useRef<HTMLDivElement>(null)
  const dropdownArrowRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (open) {
      setTimeout(() => {
        if (
          !bellRef.current ||
          !dropdownContentRef.current ||
          !dropdownArrowRef.current
        ) {
          return
        }

        const bellRefRightOffset = bellRef.current.getBoundingClientRect().right
        const bellRefWidth = bellRef.current.getBoundingClientRect().width
        const dropdownContentRefRightOffset =
          dropdownContentRef.current.getBoundingClientRect().right
        const dropdownArrowOffset = Math.round(
          dropdownContentRefRightOffset - bellRefRightOffset + bellRefWidth / 2
        )
        dropdownArrowRef.current.style.right = `${dropdownArrowOffset}px`
      }, 0)
    }
  }, [bellRef, dropdownContentRef, open])

  const { logEvent } = useLogEvent()

  return (
    <DropdownMenu open={open} onOpenChange={onOpenChange}>
      <DropdownMenuTrigger asChild>
        <IconButton
          count={unreadCount}
          ref={bellRef}
          className={cn(
            "bg-transparent",
            ccls({
              [CommunitySlug.Marketingland]: "text-white",
              [CommunitySlug.Gotomillions]: "text-white",
              default: "text-primary",
            })
          )}
        >
          <Bell />
        </IconButton>
      </DropdownMenuTrigger>
      <DropdownMenuContent
        align="end"
        avoidCollisions={true}
        sideOffset={10}
        alignOffset={-12}
        onCloseAutoFocus={(e) => e.preventDefault()}
        className="p-0 text-2xs leading-4 w-[302px] max-h-[calc(100vh-100px)] overflow-scroll"
        ref={dropdownContentRef}
      >
        <div className={`absolute top-[-7px]`} ref={dropdownArrowRef}>
          <DropdownArrow />
        </div>
        <div className="px-5 py-2 uppercase border-b border-b-borderColor text-foreground tracking-[0.5px]">
          Notifications
        </div>
        {loading && notifications.length === 0 ? (
          <div className="flex justify-center p-4">
            <LoadingIndicator />
          </div>
        ) : error ? (
          <div className="px-5 p-4">Error loading notifications</div>
        ) : notifications.length === 0 ? (
          <div className="px-5 p-4 tracking-[0.5px]">
            You have no current notifications.
          </div>
        ) : (
          data && (
            <div>
              {notifications.map((notification) => (
                <Link
                  onClick={(e: React.MouseEvent) => {
                    setOpen(false)
                    markNotificationRead(notification.id)

                    if (e.target instanceof HTMLAnchorElement) {
                      logEvent(AhoyEventTypeEnum.NotificationLinkClicked, {
                        url_clicked: e.target.href,
                      })
                    }
                  }}
                  to={pathForNotification(notification)}
                  key={notification.id}
                  className={cn(
                    "block group px-5 cursor-pointer",
                    !notification.unread && "hover:bg-hover",
                    notification.unread && "bg-card-highlight-background"
                  )}
                >
                  <div className="w-full flex items-center py-4 border-t border-t-borderColor group-first:border-t-0">
                    {renderNotification(notification)}
                  </div>
                </Link>
              ))}
              <InfiniteLoadMore
                onEndReached={() =>
                  fetchMore({
                    variables: {
                      notificationsCursor:
                        data.notifications.pageInfo.endCursor,
                    },
                  })
                }
                canLoadMore={
                  !loading && data.notifications.pageInfo.hasNextPage
                }
                loadingText=""
                loading={
                  loading &&
                  notifications.length > 0 &&
                  data.notifications.pageInfo.hasNextPage
                }
                className="flex justify-center p-4"
              />
            </div>
          )
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

const ArticlePublishText = ({
  title,
  contentType,
}: {
  title?: ArticleRevision["title"]
  contentType?: ArticleContentTypeEnum
}) => {
  if (contentType === ArticleContentTypeEnum.Course) {
    return (
      <>
        a whole course on{" "}
        <span className="font-medium text-foreground">{title}</span>! Enrollment
        is open!
      </>
    )
  } else {
    return (
      <>
        <span className="font-medium text-foreground">{title}</span> in the
        Library!
      </>
    )
  }
}

const courseTakers = (users: UserNameProps["user"][]) => {
  const count = users.length

  return (
    <>
      {count === 1 && (
        <>
          <UserName user={users[0]} /> is
        </>
      )}
      {count === 2 && (
        <>
          <UserName user={users[0]} /> and <UserName user={users[1]} /> are
        </>
      )}
      {count === 3 && (
        <>
          <UserName user={users[0]} />, <UserName user={users[1]} />, and 1 peer
          are
        </>
      )}
      {count > 3 && (
        <>
          <UserName user={users[0]} />, <UserName user={users[1]} />, and{" "}
          {count - 2} peers are
        </>
      )}
    </>
  )
}

const taggedOn = (source: NotificationsResultFragment["source"]) => {
  if (source?.__typename === "Post" && source.parentPostId) {
    return "reply"
  } else if (source?.__typename === "Post") {
    return "post"
  } else {
    return "comment"
  }
}

gql(`
  fragment NotificationsResult on Notification {
    id
    action
    unread
    postedAt
    actor {
      ...User_Avatar
      name
    }
    source {
      ... on Post {
        id
        parentPostId
        repliesCount
        reactorsCount
        commentersCount
        currentUserHasReplied
        content
        retweetPost {
          content
        }
      }
      ... on Article {
        id
        state
        commentersCount
        currentUserHasCommented
        approvedRevision {
          id
          title
          contentType
        }
        draftRevision {
          id
          title
        }
        collaborators {
          id
        }
        bookmarkedUserCount
        firstNBookmarkedUsers(count: 2) {
          id
          name
        }
      }
      ... on CalendarEvent {
        id
        name
      }
      ... on Celebration {
        id
        celebrationType
        customCelebrationType
        date
        user {
          id
          firstName
        }
      }
      __typename
    }
  }
`)

const NOTIFICATIONS_QUERY_DOCUMENT = gql(`
  query Notifications($notificationsCursor: String) {
    notifications(first: 50, after: $notificationsCursor) {
      edges {
        node {
          ...NotificationsResult
        }
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`)
