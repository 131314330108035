import { Offer, TierIntervalEnum } from "~/__generated__/graphql"
import { useWizard } from "~/ui/Wizard"
import { useSubscription } from "../SubscriptionProvider"
import { useCallback, useEffect, useMemo, useRef } from "react"
import { LoadingStep } from "./LoadingStep"
import { PricingTableTier } from "./PricingTableStep"
import toast from "react-hot-toast"
import { useUserOffers } from "~/offers/UserOffersProvider"
import { stripeCheckoutSessionReturnPath } from "~/common/paths"

export const CreateCheckoutSessionStep = () => {
  const { goToStep, meta, addToMeta } = useWizard()
  const { createCheckoutSession } = useSubscription()
  const { getBestUserOffer } = useUserOffers()

  const { offer, selectedTier, selectedInterval } = useMemo(() => {
    const selectedTier = meta.selectedTier as PricingTableTier
    const selectedInterval = meta.selectedInterval as TierIntervalEnum
    const offer = meta.offer
      ? (meta.offer as Offer)
      : getBestUserOffer(selectedTier.level, selectedInterval)?.offer

    return {
      offer,
      selectedTier,
      selectedInterval,
    }
  }, [meta.selectedTier, meta.selectedInterval, meta.offer, getBestUserOffer])

  const initialized = useRef(false)

  const changeTier = useCallback(async () => {
    if (!(selectedTier && selectedInterval)) {
      toast.error("An unexpected error has occurred. Please try again later.")
      return
    }

    const currentUrl = new URL(window.location.href)
    currentUrl.searchParams.set("redirect", "1")

    const returnUrl =
      window.location.origin +
      stripeCheckoutSessionReturnPath.pattern +
      "?return_url=" +
      encodeURIComponent(currentUrl.toString())

    const { checkoutSession, errors } = await createCheckoutSession(
      selectedTier.level,
      selectedInterval,
      returnUrl,
      offer?.id
    )
    if (errors) return

    if (checkoutSession) {
      addToMeta("checkoutSession", checkoutSession)
      goToStep("CheckoutStep")
      return
    } else {
      console.log({ checkoutSession })
      console.log("no idea what to do here")
      return
    }
  }, [
    selectedTier,
    selectedInterval,
    goToStep,
    addToMeta,
    offer,
    createCheckoutSession,
  ])

  const isReady = useMemo(() => {
    return selectedTier && selectedInterval
  }, [selectedTier, selectedInterval])

  useEffect(() => {
    if (isReady && !initialized.current) {
      initialized.current = true
      changeTier()
    }
  }, [changeTier, isReady])

  return <LoadingStep />
}

CreateCheckoutSessionStep.displayName = "CreateCheckoutSessionStep"
