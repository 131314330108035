import { useMemo } from "react"

export const useInternalSearchParams = () => {
  return useMemo(() => {
    const url = window.location.href
    const queryString = url.split("?")[1] || ""

    // Fix malformed `&#038;` by replacing it with `&`
    const fixedQueryString = queryString.replace(/&#038;/g, "&")

    return new URLSearchParams(fixedQueryString)
  }, [])
}
