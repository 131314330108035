import { QueryResult, useQuery } from "@apollo/client"
import { gql } from "~/__generated__"
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from "react"
import invariant from "tiny-invariant"
import {
  CurrentUserProviderQuery,
  Exact,
  InputMaybe,
  Scalars,
} from "~/__generated__/graphql"
import { CURRENT_USER_QUERY_DOCUMENT } from "./currentUserQuery"
import { GraphqlError } from "~/ui/GraphqlError"
import { useDeviceLocation, LatLng } from "~/common/useDeviceLocation"
import { useSafeMutation } from "~/common/useSafeMutation"
import { useLocalStorage } from "@uidotdev/usehooks"
import { LeadProvider } from "~/common/LeadContext"
import { USER_UPDATE_MUTATION } from "~/common/userUpdateMutation"
import { SubscriptionProvider } from "~/subscriptions/SubscriptionProvider"
import { RulesEngineProvider } from "~/rules-engine/RulesEngineContext"
import { UserOffersProvider } from "~/offers/UserOffersProvider"
import { currentUserData } from "."
import { EventsProvider } from "~/analytics/EventsContext"

interface LocationData {
  enableLocation: boolean | undefined
  updateUserLocation: (args: { checkPermissions?: boolean }) => void
  setEnableLocationAndDevicePreference: (preference: boolean) => void
  deviceLatLng: LatLng | null
  fetchLocation: () => void
  fetchingDeviceLatLng: boolean
  fetchingPermissions: boolean
  locationServicesEnabled: boolean | null
}

interface CurrentUserContextType {
  result: QueryResult<
    CurrentUserProviderQuery,
    Exact<{
      id?: InputMaybe<Scalars["ID"]["input"]>
    }>
  >
  currentUser: CurrentUserProviderQuery["user"]
  locationData: LocationData
}

const CurrentUserContext = createContext<CurrentUserContextType | null>(null)

export const CurrentUserProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const result = useQuery(CURRENT_USER_QUERY_DOCUMENT, {
    variables: { id: currentUserData.data.user?.id },
    fetchPolicy: "cache-first",
    pollInterval: 30000,
  })
  const currentUser = result.data?.user
  const [runUserUpdate] = useSafeMutation(USER_UPDATE_MUTATION)

  const [deviceLocationPreference] = useLocalStorage("enableLocation", false)
  const enableLocation = useMemo(() => {
    return !!currentUser?.deviceLocationEnabled
  }, [currentUser])

  const {
    deviceLatLng,
    setDeviceLatLng,
    fetchLocation,
    fetchingDeviceLatLng,
    fetchingPermissions,
    locationServicesEnabled,
  } = useDeviceLocation()

  const [runUpdateCurrentLocation] = useSafeMutation(
    USER_UPDATE_CURRENT_LOCATION_MUTATION
  )

  const [runDestroyCurrentLocation] = useSafeMutation(
    USER_DESTROY_CURRENT_LOCATION_MUTATION
  )

  useEffect(() => {
    const interval = setInterval(() => {
      if (deviceLatLng && currentUser) {
        runUpdateCurrentLocation({
          variables: {
            input: { lat: deviceLatLng.lat, lng: deviceLatLng.lng },
          },
        })
      }
    }, 5000)

    return () => clearInterval(interval)
  }, [runUpdateCurrentLocation, deviceLatLng, currentUser])

  const setEnableLocationAndDevicePreference = useCallback(
    (val: boolean) => {
      runUserUpdate({
        variables: {
          input: {
            deviceLocationEnabled: val,
          },
        },
      })

      if (!val) {
        setDeviceLatLng(null)
        runDestroyCurrentLocation({
          variables: {
            input: {},
          },
        })
      }
    },
    [setDeviceLatLng, runUserUpdate, runDestroyCurrentLocation]
  )

  const updateUserLocation = useCallback(
    ({ checkPermissions = true }) => {
      const enabled =
        (checkPermissions && enableLocation && locationServicesEnabled) ||
        !checkPermissions

      if (enabled && !fetchingDeviceLatLng && currentUser) {
        fetchLocation({
          refresh: true,
          callback: (coords: LatLng) => {
            runUpdateCurrentLocation({ variables: { input: coords } })
          },
        })
      }
    },
    [
      fetchLocation,
      locationServicesEnabled,
      enableLocation,
      fetchingDeviceLatLng,
      runUpdateCurrentLocation,
      currentUser,
    ]
  )

  const hasBackfilledDeviceLocationEnabled = useRef(false)
  useEffect(() => {
    if (
      currentUser &&
      currentUser.deviceLocationEnabled === null &&
      !hasBackfilledDeviceLocationEnabled.current
    ) {
      // Previously, the location enabled permissions existed only on the frontend client. If this is the case, update
      // the user record to set `deviceLocationEnabled` to true
      hasBackfilledDeviceLocationEnabled.current = true

      const updateUser = async () => {
        await runUserUpdate({
          variables: {
            input: {
              deviceLocationEnabled: deviceLocationPreference,
            },
          },
        })
      }
      updateUser()
    }
  }, [currentUser, runUserUpdate, deviceLocationPreference])

  // This is to trigger an update on Mount and whenever the location services
  // state is toggled.
  useEffect(() => {
    if (locationServicesEnabled) {
      updateUserLocation({ checkPermissions: true })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationServicesEnabled])

  useEffect(() => {
    const intervalId = setInterval(() => {
      updateUserLocation({ checkPermissions: true })
    }, 60_000)

    return () => clearInterval(intervalId)
  }, [updateUserLocation])

  const value = useMemo(() => {
    return {
      result,
      locationData: {
        enableLocation: enableLocation,
        updateUserLocation: updateUserLocation,
        setEnableLocationAndDevicePreference:
          setEnableLocationAndDevicePreference,
        deviceLatLng: deviceLatLng,
        fetchLocation: fetchLocation,
        fetchingDeviceLatLng: fetchingDeviceLatLng,
        fetchingPermissions: fetchingPermissions,
        locationServicesEnabled: locationServicesEnabled,
      },
      currentUser,
    }
  }, [
    result,
    currentUser,
    enableLocation,
    updateUserLocation,
    setEnableLocationAndDevicePreference,
    deviceLatLng,
    fetchLocation,
    fetchingDeviceLatLng,
    fetchingPermissions,
    locationServicesEnabled,
  ])

  if (result.error) return <GraphqlError error={result.error} />

  return (
    <CurrentUserContext.Provider value={value}>
      <LeadProvider>
        <EventsProvider>
          <UserOffersProvider>
            <RulesEngineProvider>
              <SubscriptionProvider
                subscription={
                  currentUser?.activeStripeSubscription || undefined
                }
              >
                {children}
              </SubscriptionProvider>
            </RulesEngineProvider>
          </UserOffersProvider>
        </EventsProvider>
      </LeadProvider>
    </CurrentUserContext.Provider>
  )
}

export const useCurrentUserMaybe = () => {
  const contextValue = useContext(CurrentUserContext)
  if (contextValue === null) {
    throw Error("Context has not been Provided!")
  }
  return contextValue
}

export const useCurrentUser = () => {
  const contextValue = useContext(CurrentUserContext)
  invariant(contextValue, "Context has not been Provided!")
  invariant(contextValue.currentUser, "User must be logged in")

  return {
    currentUser: contextValue.currentUser,
    refetch: contextValue.result.refetch,
    startPolling: contextValue.result.startPolling,
    stopPolling: contextValue.result.stopPolling,
    locationData: contextValue.locationData,
  }
}

const USER_UPDATE_CURRENT_LOCATION_MUTATION = gql(`
  mutation UserCurrentLocationUpdate($input: UserCurrentLocationUpdateInput!) {
    userCurrentLocationUpdate(input: $input) {
      currentLocation {
        id
        lat
        lng
      }
    }
  }
`)

const USER_DESTROY_CURRENT_LOCATION_MUTATION = gql(`
  mutation UserCurrentLocationDestroy($input: UserCurrentLocationDestroyInput!) {
    userCurrentLocationDestroy(input: $input) {
      success
    }
  }
`)
