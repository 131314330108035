import clsx from "clsx"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { Link } from "~/ui/Link"
import SmsNotification from "../images/icons/sms-notification.svg?react"
import {
  bookmarksPath,
  feedPath,
  messagesPath,
  editProfilePath,
  accountSettingsPath,
  adminDashboardPath,
} from "~/common/paths"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { NavItems } from "~/components/NavItems"
import { AvatarWithFallback } from "~/ui/AvatarWithFallback"
import { useMessagesSubscription } from "~/chat/useMessagesSubscription"
import { useUserUpdatedSubscription } from "~/notifications/useUserUpdatedSubscription"
import { CommunitySlug } from "~/__generated__/graphql"
import { useCommunityClassname } from "~/community/useCommunity"
import { NotificationDropdown } from "~/notifications/NotificationDropdown"
import { Notifications } from "~/common/Notifications"
import { UserDialogContextProvider } from "~/directory/UserDialogContext"
import { StyledDropdown, StyledDropdownLink } from "~/ui/StyledDropdown"

import UserSquare from "../images/icons/user-square.svg?react"
import CreditCard from "../images/icons/credit-card.svg?react"
import Bookmark from "../images/icons/bookmark.svg?react"
import Menu from "../images/icons/menu.svg?react"
import { cn } from "~/lib/utils"
import { IconButton } from "~/ui/IconButton"
import { CTALayout } from "./CTALayout"
import { GearIcon } from "@radix-ui/react-icons"
import { CommunityLogo } from "~/community/CommunityLogo"
import { MergeContextProvider } from "~/merge/MergeContext"
import { ChannelsProvider } from "~/common/ChannelsProvider"

const AuthenticatedLayoutContext = React.createContext<{
  setIsBlurred: (isBlurred: boolean) => void
  setLeftSidebar: (leftSidebar: React.ReactNode | null) => void
  resetLeftSidebar: () => void
}>({
  setIsBlurred: () => {},
  setLeftSidebar: () => {},
  resetLeftSidebar: () => {},
})

export const useAuthenticatedLayout = () => {
  return React.useContext(AuthenticatedLayoutContext)
}

export const AuthenticatedLayout = () => {
  const { currentUser } = useCurrentUser()
  const [navOpen, setNavOpen] = useState<boolean>(false)
  const [isBlurred, setIsBlurred] = useState<boolean>(false)
  const DEFAULT_LEFT_SIDEBAR = useMemo(() => <NavItems />, [])
  const [leftSidebar, setLeftSidebar] = useState<React.ReactNode | null>(
    DEFAULT_LEFT_SIDEBAR
  )
  const resetLeftSidebar = useCallback(() => {
    setLeftSidebar(DEFAULT_LEFT_SIDEBAR)
  }, [DEFAULT_LEFT_SIDEBAR])
  const location = useLocation()

  useEffect(() => {
    setNavOpen(false)
  }, [location])

  const ccls = useCommunityClassname()
  const navigate = useNavigate()

  // Ensure that header unread message count gets updated whether or not
  // you're on the messages page.  Maybe we can improve this in the future.
  useMessagesSubscription()
  useUserUpdatedSubscription()

  return (
    <AuthenticatedLayoutContext.Provider
      value={{ setIsBlurred, setLeftSidebar, resetLeftSidebar }}
    >
      <ChannelsProvider>
        <MergeContextProvider>
          <UserDialogContextProvider>
            <div className={`flex-1 flex flex-col ${isBlurred ? "blur" : ""}`}>
              {currentUser.onboarded && <Notifications />}

              <nav
                role="navigation"
                aria-label="Main menu"
                aria-controls="main-menu"
              >
                <div
                  className={cn(
                    "p-4 border-b",
                    ccls({
                      [CommunitySlug.Marketingland]: "border-primary",
                      default: "border-mercury",
                    })
                  )}
                >
                  <div className="mx-auto container flex items-center">
                    <div className="lg:hidden w-[30px]">
                      <div
                        role="button"
                        aria-expanded={navOpen}
                        onClick={() => {
                          setNavOpen(!navOpen)
                        }}
                        className={ccls({
                          [CommunitySlug.Boardroom]: "text-white",
                          [CommunitySlug.Gotomillions]: "text-white",
                          default: "text-primary",
                        })}
                      >
                        <Menu />
                      </div>
                    </div>
                    <Link
                      to={feedPath.pattern}
                      className="block flex-grow"
                      aria-label="Homepage"
                    >
                      <CommunityLogo
                        className={cn(
                          "w-auto hidden md:block",
                          ccls({
                            [CommunitySlug.Gotomillions]: "max-h-16",
                            default: "max-h-8",
                          })
                        )}
                      />
                      <CommunityLogo
                        className="md:hidden w-[35px] h-[35px] rounded-md"
                        variant="square"
                      />
                    </Link>
                    <div
                      className={cn(
                        "flex items-center gap-4",
                        ccls({
                          [CommunitySlug.Boardroom]: "text-white",
                          [CommunitySlug.Gotomillions]: "text-white",
                          default: "text-primary",
                        })
                      )}
                    >
                      <NotificationDropdown
                        unreadCount={currentUser.unreadNotificationCount}
                      />
                      <IconButton
                        count={currentUser.unreadMessageCount}
                        className={cn(
                          "bg-transparent",
                          ccls({
                            [CommunitySlug.Marketingland]: "text-white",
                            [CommunitySlug.Gotomillions]: "text-white",
                            default: "text-primary",
                          })
                        )}
                        onClick={() => {
                          navigate(messagesPath.pattern)
                        }}
                      >
                        <SmsNotification />
                      </IconButton>
                      <StyledDropdown
                        trigger={
                          <AvatarWithFallback
                            user={currentUser}
                            className="bg-background"
                            nonAdminClassname="border-background"
                          />
                        }
                      >
                        <StyledDropdownLink
                          linkProps={{
                            to: editProfilePath.pattern,
                          }}
                          title="My Profile"
                          icon={<UserSquare />}
                        />
                        <StyledDropdownLink
                          linkProps={{ to: accountSettingsPath.pattern }}
                          title="Settings"
                          icon={<GearIcon height={18} width={18} />}
                        />
                        <StyledDropdownLink
                          linkProps={{
                            to: bookmarksPath.pattern,
                          }}
                          title="Bookmarks"
                          icon={<Bookmark height={25} width={25} />}
                        />
                        {currentUser.admin && (
                          <StyledDropdownLink
                            linkProps={{
                              to: adminDashboardPath.pattern,
                            }}
                            title="Admin Panel"
                            icon={<CreditCard />}
                          />
                        )}
                      </StyledDropdown>
                    </div>
                  </div>
                </div>

                <div
                  className={clsx(
                    "absolute w-full min-h-full overflow-auto transition-transform duration-300 ease-out bg-background border-b border-mercury z-20 p-4",
                    {
                      "transform translate-x-0": navOpen,
                      "transform -translate-x-full": !navOpen,
                    }
                  )}
                  id="main-menu"
                >
                  <div className="container mx-auto">{leftSidebar}</div>
                </div>
              </nav>

              <div className="flex flex-1 flex-col">
                <CTALayout>
                  <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-5 container mx-auto my-5">
                    {leftSidebar && (
                      <div className="hidden lg:block">{leftSidebar}</div>
                    )}
                    <div
                      className={cn(
                        "md:col-span-3",
                        !leftSidebar && "lg:col-span-4"
                      )}
                    >
                      <Outlet />
                    </div>
                  </div>
                </CTALayout>
              </div>
            </div>
          </UserDialogContextProvider>
        </MergeContextProvider>
      </ChannelsProvider>
    </AuthenticatedLayoutContext.Provider>
  )
}
