import {
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react"
import { gql } from "~/__generated__"
import { useWizard } from "~/ui/Wizard"
import { PricingTableTier } from "./PricingTableStep"
import { AhoyEventTypeEnum, TierIntervalEnum } from "~/__generated__/graphql"
import { useSafeMutation } from "~/common/useSafeMutation"
import {
  CheckoutProvider,
  PaymentElement,
  useCheckout,
  useStripe,
} from "@stripe/react-stripe-js"
import { LoadingStep } from "./LoadingStep"
import { useTiers } from "~/tiers/TiersProvider"
import { DialogFooter, DialogHeader, DialogTitle } from "~/ui/dialog"
import { Button } from "~/ui/button"
import { TextField } from "~/components/forms/TextField"
import { z } from "zod"
import { useForm } from "react-hook-form"
import { zodResolver } from "@hookform/resolvers/zod"
import { Form } from "~/ui/form"
import { useCurrentUser } from "~/auth/CurrentUserContext"
import { USER_UPDATE_MUTATION } from "~/common/userUpdateMutation"
import { useLocalStorage } from "usehooks-ts"
import { Muted, Strong } from "~/ui/typography"
import { formatCurrency } from "~/common/formatCurrency"
import { CheckboxField } from "~/components/forms/CheckboxField"
import { Translation } from "~/common/Translation"
import { Link } from "~/ui/Link"
import { useCommunity } from "~/community/useCommunity"
import { subscriberAgreementPath } from "~/common/paths"
import { cn } from "~/lib/utils"
import { getMetaVar } from "~/common/getMetaVar"
import { loadStripe } from "@stripe/stripe-js"
import { Separator } from "~/ui/separator"
import { Card, CardContent } from "~/ui/card"
import { Input } from "~/ui/input"
import { useLogEvent } from "~/analytics/EventsContext"

const stripePublishableKey = getMetaVar("stripe-publishable-key")
const stripePromise = loadStripe(stripePublishableKey, {
  betas: ["custom_checkout_beta_5"],
})

const formSchema = z.object({
  firstName: z.string().min(2, { message: "First name is required" }),
  lastName: z.string().min(2, { message: "Last name is required" }),
  consent: z.boolean().refine((value) => value === true, {
    message: "You must agree to the terms and conditions to subscribe",
  }),
})

export type FormValues = z.infer<typeof formSchema>

export const CheckoutStep = () => {
  const { meta } = useWizard()
  const { selectedTier, selectedInterval, checkoutSession } = useMemo(() => {
    const selectedTier = meta.selectedTier as PricingTableTier
    const selectedInterval = meta.selectedInterval as TierIntervalEnum
    const checkoutSession = meta.checkoutSession as
      | {
          id: string
          clientSecret: string
          livemode: boolean
          amountTotal: number
          amountSubtotal: number
        }
      | null
      | undefined
    return {
      selectedTier,
      selectedInterval,
      checkoutSession,
    }
  }, [meta.selectedTier, meta.selectedInterval, meta.checkoutSession])
  const [clientSecret, setClientSecret] = useState<string | null>(
    checkoutSession?.clientSecret || null
  )
  const { formatTierName } = useTiers()

  useEffect(() => {
    if (checkoutSession) {
      setClientSecret(checkoutSession.clientSecret)
    }
  }, [checkoutSession])

  if (!selectedTier) return <LoadingStep />

  return (
    <>
      <DialogHeader>
        <DialogTitle>
          Subscribe to {formatTierName(selectedTier, selectedInterval)}
        </DialogTitle>
      </DialogHeader>

      {clientSecret && (
        <CheckoutProvider stripe={stripePromise} options={{ clientSecret }}>
          <CheckoutForm checkoutSession={checkoutSession || undefined} />
        </CheckoutProvider>
      )}
      {!clientSecret && <CheckoutForm />}
    </>
  )
}
CheckoutStep.displayName = "CheckoutStep"

const CheckoutForm = ({
  paymentIntent,
  checkoutSession,
}: {
  paymentIntent?: {
    id: string
    clientSecret: string
    livemode: boolean
    status: string
    amount: number
  }
  checkoutSession?: {
    id: string
    clientSecret: string
    livemode: boolean
    amountTotal: number
    amountSubtotal: number
  }
}) => {
  const { termsOfUseUrl, privacyPolicyUrl } = useCommunity()
  const stripe = useStripe()
  const { meta, goToStep, back } = useWizard()
  const { currentUser } = useCurrentUser()
  const checkout = useCheckout()
  const { logEvent } = useLogEvent()

  const form = useForm<FormValues>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstName: currentUser.firstName || "",
      lastName: currentUser.lastName || "",
    },
  })
  const [runUserUpdate] = useSafeMutation(USER_UPDATE_MUTATION)
  const [runUserRefreshStripeSubscriptions] = useSafeMutation(
    USER_REFRESH_STRIPE_SUBSCRIPTIONS
  )

  const [, setReturnPath] = useLocalStorage("returnPath", "")
  const [, setReturnMeta] = useLocalStorage("returnMeta", {})

  const [isLoading, setIsLoading] = useState(false)

  const handleSubmit = useCallback(
    async (formValues: FormValues) => {
      if (!stripe) return
      setIsLoading(true)

      if (!currentUser.firstName || !currentUser.lastName) {
        await runUserUpdate({
          variables: {
            input: {
              firstName: formValues.firstName || currentUser.firstName,
              lastName: formValues.lastName || currentUser.lastName,
            },
          },
        })
      }

      setReturnMeta({
        ...meta,
        initialManageSubscriptionWizardStep: "SocialContractStep",
      })
      setReturnPath(window.location.pathname)

      const result = await checkout.confirm()

      if (result.type === "success") {
        setReturnMeta({})
        setReturnPath("")
        logEvent(AhoyEventTypeEnum.StripeCheckoutCompleted)

        await runUserUpdate({
          variables: {
            input: {
              initialStripeCheckoutCompletedAt: new Date(),
            },
          },
        })

        // This is really only necessary in environments where we don't receive Stripe webhooks
        await runUserRefreshStripeSubscriptions()

        goToStep("SocialContractStep", "forward", false)
      } else {
        console.error(result.error)
      }

      setIsLoading(false)
    },
    [
      stripe,
      currentUser,
      runUserUpdate,
      goToStep,
      runUserRefreshStripeSubscriptions,
      meta,
      setReturnPath,
      setReturnMeta,
      checkout,
      logEvent,
    ]
  )

  const formatStripeInterval = ({
    interval,
    intervalCount,
  }: {
    interval: string
    intervalCount: number
  }) => {
    if (interval === "month" && intervalCount === 1) {
      return "monthly"
    } else if (interval === "year" && intervalCount === 1) {
      return "annually"
    } else if (intervalCount === 1) {
      return `every ${interval}`
    } else {
      return `every ${intervalCount} ${interval}s`
    }
  }

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <div className="flex flex-col gap-4">
          <Card>
            <CardContent className="pt-4 flex flex-col gap-4">
              <div className="grid grid-cols-2 gap-4">
                {checkout.lineItems.map((lineItem) => (
                  <Fragment key={lineItem.id}>
                    <div className="flex flex-col gap-1 text-xs">
                      <Strong>{lineItem.name}</Strong>
                      {lineItem.recurring && (
                        <Muted>
                          Billed {formatStripeInterval(lineItem.recurring)}
                        </Muted>
                      )}
                    </div>
                    <div className="text-right text-xs">
                      {formatCurrency(lineItem.amountSubtotal)}
                    </div>
                  </Fragment>
                ))}
              </div>
              <Separator />
              <div className="grid grid-cols-2 gap-4 text-xs">
                <div>
                  <Strong>Subtotal</Strong>
                </div>
                <div className="text-right">
                  {formatCurrency(checkout.total.subtotal)}
                </div>

                {checkout.discountAmounts &&
                  checkout.discountAmounts.map((discountAmount) => (
                    <Fragment key={discountAmount.promotionCode}>
                      <div>
                        <Strong>{discountAmount.displayName}</Strong>
                      </div>
                      <div className="text-right">
                        {formatCurrency(0 - discountAmount.amount)}
                      </div>
                    </Fragment>
                  ))}

                {(!checkout.discountAmounts ||
                  checkout.discountAmounts.length === 0) && (
                  <div className="col-span-2">
                    <CouponCodeField />
                  </div>
                )}
              </div>
              <Separator />
              <div className="grid grid-cols-2 gap-4 text-xs">
                <div>
                  <Strong>Total due today</Strong>
                </div>
                <div className="text-right text-sm">
                  <Strong>{formatCurrency(checkout.total.total)}</Strong>
                </div>
              </div>
            </CardContent>
          </Card>

          <div className={cn("grid grid-cols-2 gap-4 mt-4")}>
            <TextField
              control={form.control}
              name="firstName"
              label="First Name"
              disabled={isLoading || !!currentUser.firstName}
            />
            <TextField
              control={form.control}
              name="lastName"
              label="Last Name"
              disabled={isLoading || !!currentUser.lastName}
            />
          </div>

          {checkoutSession && checkoutSession.amountTotal > 0 && (
            <PaymentElement />
          )}

          <CheckboxField
            control={form.control}
            name="consent"
            label={
              <Translation
                ns="signup"
                i18nKey="consent"
                components={{
                  TermsOfService: (
                    <Link
                      href={termsOfUseUrl || undefined}
                      target="_blank"
                      rel="noreferrer nofollow"
                    />
                  ),
                  PrivacyPolicy: (
                    <Link
                      href={privacyPolicyUrl || undefined}
                      target="_blank"
                      rel="noreferrer nofollow"
                    />
                  ),
                  SubscriberAgreement: (
                    <Link
                      href={subscriberAgreementPath.pattern}
                      target="_blank"
                      rel="noreferrer nofollow"
                    />
                  ),
                }}
              />
            }
            labelClassName="font-normal text-2xs text-left"
            required
            disabled={isLoading}
          />

          <DialogFooter>
            <Button
              type="button"
              variant="link"
              size="inline"
              onClick={back}
              disabled={isLoading}
            >
              Back
            </Button>
            <Button type="submit" disabled={isLoading}>
              Subscribe
            </Button>
          </DialogFooter>
        </div>
      </form>
    </Form>
  )
}

const CouponCodeField = () => {
  const [isActive, setIsActive] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const checkout = useCheckout()
  const [couponCodeValue, setCouponCodeValue] = useState("")
  const [error, setError] = useState<string | null>(null)

  const applyCouponCode = useCallback(async () => {
    console.log("applying coupon code", couponCodeValue)
    if (!couponCodeValue) return
    const response = await checkout.applyPromotionCode(couponCodeValue)
    response.type === "error" && setError(response.error.message)
  }, [couponCodeValue, checkout])

  const changeValue = useCallback((value: string) => {
    console.log("changing value", value)
    setCouponCodeValue(value)
    setError(null)
  }, [])

  return (
    <>
      {!isActive ? (
        <Button
          variant="link"
          size="inline"
          className="text-link"
          onClick={() => {
            setIsActive(true)
            setTimeout(() => {
              inputRef.current?.focus()
            }, 0)
          }}
        >
          Add promotion code
        </Button>
      ) : (
        <div className="flex flex-col gap-1">
          <Input
            ref={inputRef}
            type="text"
            placeholder="Add promotion code"
            value={couponCodeValue}
            onChange={(e) => changeValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.stopPropagation()
                e.preventDefault()
                applyCouponCode()
              }
            }}
            className={cn(error ? "border-error text-error" : "")}
            endAdornment={
              <>
                {!error && couponCodeValue && (
                  <Button
                    variant="link"
                    type="button"
                    onClick={applyCouponCode}
                  >
                    Apply
                  </Button>
                )}
              </>
            }
          />
          {error && <div className="text-error text-xs">{error}</div>}
        </div>
      )}
    </>
  )
}

const USER_REFRESH_STRIPE_SUBSCRIPTIONS = gql(`
  mutation UserRefreshStripeSubscriptions {
    userRefreshStripeSubscriptions(input: {}) {
      user {
        ...User_CurrentUser
      }
    }
  }
`)
