import { useMemo } from "react"
import { useCommunity } from "./useCommunity"
import { Link } from "~/ui/Link"

export const CommunityUrl = () => {
  const community = useCommunity()
  const origin = useMemo(() => {
    const url = new URL(community.origin)
    return url.hostname
  }, [community.origin])

  return (
    <Link href={community.origin} className="whitespace-nowrap">
      {origin}
    </Link>
  )
}
