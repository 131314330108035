import { matchPath } from "react-router-dom"
import { EventPageTypeEnum } from "../__generated__/graphql"

// use static-path to generate type-safe paths
// https://github.com/garybernhardt/static-path
import { path } from "static-path"

export const loadingPath = path("/")
export const feedPath = path("/feed")
export const channelPath = path("/channel/:channelSlug")

export const signInPath = path("/sign-in")
export const signInTokenPath = path("/users/sign_in/:sessionId")
export const signUpPath = path("/sign-up")

export const editProfilePath = path("/account/profile")
export const editCelebrationsPath = path("/account/profile#celebrations")
export const editInterestsPath = path("/account/profile#interests")
export const editExpertisePath = path("/account/profile#expertise")
export const accountSettingsPath = path("/account/settings")
export const editIntroductionsPath = path("/account/settings#introductions")
export const editSubscriptionPath = path("/account/settings#subscription")
export const editNotificationsPath = path("/account/settings#notifications")

export const welcomePath = path("/onboarding")
export const activatePath = path("/onboarding/activate")
export const aboutYouPath = path("/onboarding/all-about-you")
export const experiencePath = path("/onboarding/experience")
export const onboardingBioPath = path("/onboarding/bio")
export const onboardingInterestsPath = path("/onboarding/interests")
export const onboardingExpertisePath = path("/onboarding/expertise")
export const onboardingIntroductionsPath = path("/onboarding/introductions")
export const onboardingFitWaitingPath = path("/onboarding/waiting-room")
export const unfitPath = path("/unable-to-activate")

export const applicationLandingPath = path("/apply")
export const applicationFitWaitingScreenPath = path("/apply/waiting-room")
export const applicationMostExcitedAboutPath = path("/apply/most-excited-about")

export const subscribePath = path("/subscribe")

export const userProfilePath = path("/users/:id")

export const libraryPath = path("/library")
export const myContentPath = path("/library/my-content")
export const articlePath = path("/library/:articleId")
export const editArticlePath = path("/library/:articleId/edit")

export const postPath = path("/posts/:postId")
export const editPostPath = path("/posts/:postId/edit")

export const directoryPath = path("/directory")

export const benchmarkPath = path("/benchmark")

export const messagesPath = path("/messages")
export const dmPath = path("/messages/dm/:otherUserId")
export const roomPath = path("/messages/group/:roomId")
export const eventsPath = path("/events")
export const bookmarksPath = path("/bookmarks")
export const bookmarksTypedPath = path("/bookmarks/:bookmarkType")
export const askTheBotPath = path("/ask-the-bot")

// admin routes
export const adminPath = path("/admin")
export const adminDashboardPath = path("/admin/dashboard")
export const adminResearchToolPath = path("/admin/research-tool/:activeTab?")
export const adminResearchToolContentPath = path(
  "/admin/research-tool/contents/:contentId"
)
export const adminResearchToolCompanyPath = path(
  "/admin/research-tool/companies/:companyId/:activeTab?"
)
export const adminAdministratorsPath = path("/admin/administrators")
export const adminApplicationsPath = path("/admin/applications/:activeTab?")
export const adminMembersPath = path("/admin/members/:activeTab?")
export const adminLeadsPath = path("/admin/leads")
export const adminIntroductionsPath = path("/admin/introductions")
export const adminNewIntroductionCyclePath = path("/admin/introductions/new")
export const adminIntroductionCyclePath = path(
  "/admin/introductions/:introductionCycleId"
)
export const adminEditIntroductionCyclePath = path(
  "/admin/introductions/:introductionCycleId/edit"
)
export const adminChannelsPath = path("/admin/channels")
export const adminEventsPath = path("/admin/events")
export const adminGroupPath = path("/admin/groups/:groupId")
export const adminGroupNewPath = path("/admin/groups/new")
export const adminGroupEditPath = path("/admin/groups/:id/edit")
export const adminGroupsPath = path("/admin/groups")
export const adminLibraryPath = path("/admin/library")
export const adminProEventsPath = path("/admin/pro-events")
export const adminProEventNewPath = path("/admin/pro-events/new")
export const adminProEventEditPath = path("/admin/pro-events/:proEventId")
export const adminAutoMessagesPath = path("/admin/auto-messages/:activeTab?")
export const adminAutoMessageNewPath = path("/admin/automations/new")
export const adminAutoMessageEditPath = path("/admin/automations/:automationId")
export const adminDebugSettingsPath = path("/admin/debug-settings")
export const adminOffersPath = path("/admin/offers")
export const adminHrisPath = path("/admin/hris")
export const adminEmailSettingsPath = path("/admin/email-settings")
export const adminSailthruSyncsPath = path("/admin/sailthru-syncs")
export const adminScreenVariantsPath = path("/admin/screen-variants")
export const adminScreenVariantNewPath = path("/admin/screen-variants/new")
export const adminScreenVariantEditPath = path(
  "/admin/screen-variants/:screenVariantId"
)
export const adminExperimentsPath = path("/admin/experiments")
export const adminExperimentPath = path("/admin/experiments/:experimentName")

// server routes
export const tokenConfirmationPath = path("/users/sign_in/:id")
export const signOutPath = path("/users/sign_out")
export const goodJobPath = path("/good_job")
export const stripePortalPath = path("/billing")
export const stripeCheckoutSessionFreePath = path("/billing/free")
export const stripeCheckoutSessionPlusPath = path("/billing/plus")
export const stripeCheckoutSessionPlusQuarterlyPath = path(
  "/billing/plus-quarterly"
)
export const stripeCheckoutSessionProPath = path("/billing/pro")
export const stripeCheckoutSessionReturnPath = path("/checkout")

export const subscriberAgreementPath = path("/subscriber-agreement")
export const privacyPolicyPath = path("/privacy")

const routeTypeMapping = [
  { path: benchmarkPath.pattern, type: EventPageTypeEnum.Benchmark },
  { path: feedPath.pattern, type: EventPageTypeEnum.Feed },
  { path: postPath.pattern, type: EventPageTypeEnum.Post },
  { path: directoryPath.pattern, type: EventPageTypeEnum.Directory },
  { path: myContentPath.pattern, type: EventPageTypeEnum.MyContent },
  { path: libraryPath.pattern, type: EventPageTypeEnum.Library },
  { path: articlePath.pattern, type: EventPageTypeEnum.Article },
  { path: editArticlePath.pattern, type: EventPageTypeEnum.EditArticle },
  { path: postPath.pattern, type: EventPageTypeEnum.Post },
  { path: editPostPath.pattern, type: EventPageTypeEnum.EditPost },
  { path: eventsPath.pattern, type: EventPageTypeEnum.Events },
  {
    path: accountSettingsPath.pattern,
    type: EventPageTypeEnum.AccountSettings,
  },
  { path: channelPath.pattern, type: EventPageTypeEnum.Channel },
  {
    path: welcomePath.pattern,
    type: EventPageTypeEnum.Onboarding,
    matchSubRoutes: true,
  },
  {
    path: activatePath.pattern,
    type: EventPageTypeEnum.Onboarding,
    matchSubRoutes: true,
  },
  {
    path: onboardingBioPath.pattern,
    type: EventPageTypeEnum.Onboarding,
    matchSubRoutes: true,
  },
  {
    path: onboardingInterestsPath.pattern,
    type: EventPageTypeEnum.Onboarding,
    matchSubRoutes: true,
  },
  {
    path: onboardingExpertisePath.pattern,
    type: EventPageTypeEnum.Onboarding,
    matchSubRoutes: true,
  },
  {
    path: onboardingIntroductionsPath.pattern,
    type: EventPageTypeEnum.Onboarding,
    matchSubRoutes: true,
  },
  {
    path: bookmarksPath.pattern,
    type: EventPageTypeEnum.Bookmarks,
    matchSubRoutes: true,
  },
  { path: subscribePath.pattern, type: EventPageTypeEnum.Subscribe },
  {
    path: userProfilePath.pattern,
    type: EventPageTypeEnum.UserProfile,
    matchSubRoutes: true,
  },
  {
    path: messagesPath.pattern,
    type: EventPageTypeEnum.Messages,
    matchSubRoutes: true,
  },
  { path: askTheBotPath.pattern, type: EventPageTypeEnum.AskTheBot },
  {
    path: adminPath.pattern,
    type: EventPageTypeEnum.Admin,
    matchSubRoutes: true,
  },
  { path: stripePortalPath.pattern, type: EventPageTypeEnum.Billing },
  {
    path: editProfilePath.pattern,
    type: EventPageTypeEnum.Profile,
    matchSubRoutes: true,
  },
  {
    path: signUpPath.pattern,
    type: EventPageTypeEnum.Signup,
  },
  {
    path: applicationLandingPath.pattern,
    type: EventPageTypeEnum.Application,
    matchSubRoutes: true,
  },
]

export const getPageType = (pathname: string) => {
  for (const route of routeTypeMapping) {
    if (route.matchSubRoutes) {
      if (pathname.startsWith(route.path)) {
        return route.type
      }
    } else if (matchPath({ path: route.path }, pathname)) {
      return route.type
    }
  }

  return EventPageTypeEnum.Na
}
