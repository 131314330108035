import { useQuery } from "@apollo/client"
import { createContext, PropsWithChildren, useContext, useMemo } from "react"
import invariant from "tiny-invariant"
import { gql } from "~/__generated__"
import { ChannelsQuery } from "~/__generated__/graphql"

const ChannelsContext = createContext<{
  channels: ChannelsQuery["channels"]["nodes"]
  introductionsChannel: ChannelsQuery["channels"]["nodes"][0] | undefined
} | null>(null)

export const useChannels = () => {
  const context = useContext(ChannelsContext)
  invariant(context, "useChannels must be used within a ChannelsProvider")
  return context
}

interface ChannelsProviderProps extends PropsWithChildren<{}> {}

export const ChannelsProvider = ({ children }: ChannelsProviderProps) => {
  const { data, loading } = useQuery(CHANNELS_QUERY_DOCUMENT)
  const channels = useMemo(() => data?.channels.nodes || [], [data])

  const introductionsChannel = useMemo(
    () => channels?.find((t) => t.usedForIntroductions),
    [channels]
  )

  if (loading) {
    return null
  }

  return (
    <ChannelsContext.Provider
      value={{
        channels,
        introductionsChannel,
      }}
    >
      {children}
    </ChannelsContext.Provider>
  )
}

export const CHANNELS_QUERY_DOCUMENT = gql(`
  query Channels {
    channels {
      nodes {
        id
        name
        slug
        active
        usedForIntroductions
        unreadFeedPostCount
      }
    }
  }
`)
